.mensajeCliente {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;
  padding: 0px 40px;
}

.viasDeContacto {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

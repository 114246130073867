.formularioContainer {
  width: 500px;
}

.seccion header {
  height: 50px;
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px var(--azulQualis) solid;
  background-color: var(--beigeQualis);
}

header .logo {
  height: 30px;
  width: fit-content;
  margin-left: 20px;
  width: auto;
}

header .separador {
  width: 100%;
}

.botonsalircontainer {
  position: absolute;
  right: 0px;
}

.registroEmpresaFormularioContainer {
  padding-bottom: 40px;
}

footer .botonsalircontainer .salir {
  border: none;
  background-color: var(--azulqualis);
  color: white;
  margin-right: 20px;
  font-size: 0.9em;
  margin: 4px 15px;
}

.seccion footer {
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  width: 100%;
  height: 40px;
  background-color: var(--beigequalis);
  border-top: 1px solid var(--azulqualis);
}

footer .botonera button {
  font-size: 0.7em;
  padding: 0px;
  margin: 0px 20px;
  height: 40px;
}

footer .separador {
  width: 100%;
}

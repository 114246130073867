.tarjeta {
  padding: 20px 30px;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 6px #cccccc);
  width: fit-content;
  border-radius: 10px;
  margin: 0px 30px 20px 0px;
  height: fit-content;
  overflow: auto;
  color: black;
}

.gris {
  background-color: var(--beigeQualis);
}

.tarjeta h1 {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: 400;
}

.tarjeta.centrada {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

/* Si estoy en un celular, ajusto el ancho de la tarjeta*/
@media (width <= 900px) {
  .tarjeta.centrada {
    width: 80%;
  }
}

.buscador {
  margin-top: 30px;
}

select,
input {
  display: inline;
  font-size: 1em;
  color: var(--azulQualis);
  border: 1px #aaaaaa solid;
  border-radius: 10px;
  box-shadow: inset 2px 2px 3px #eeeeee;
}

select {
  padding: 7px;
}

input.textoCentrado {
  text-align: center;
}

input {
  padding: 8px;
  width: 65px;
}

.tarjeta button {
  margin-left: 20px;
}

.mensajeError {
  color: red;
  font-weight: 600;
  font-size: 0.9em;
}

.certificadoEncontrado {
  line-height: 2;
  margin-top: 50px;
}

.certificadoEncontrado .alcance {
  margin-top: 15px;
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 600;
}

.certificadoEncontrado .unProducto {
  margin-bottom: 20px;
}

.certificadoEncontrado .marcaAcreditacion {
  margin-top: 10px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.campoPropio {
  line-height: 1.3;
}

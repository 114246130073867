.mensajeNotificacionContainer {
  position: fixed;
  left: 50%;
  top: -100px;
  transform: translate(-50%, 0);
  z-index: 10;
  width: fit-content;
  text-align: center;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  color: white;
  transition: top 1s;
  transition-timing-function: ease-out;
  cursor: pointer;
}

.mensajeNotificacion {
  padding: 12px 20px;
  width: fit-content;
  background-color: var(--verdeQualis);
}

.mensajeNotificacionContainer.mostrar {
  top: 0px;
}

.mensajeNotificacion.error {
  background-color: var(--rojoQualis);
}

.buscador {
  text-align: left;
}

select.prettyInput {
  width: calc(100% + 20px);
}

.tarjeta button {
  margin-left: auto;
}

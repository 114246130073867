.seccion.login {
  background-color: var(--azulQualis);
}

.logoLoginContainer {
  margin-top: 50px;
  margin-bottom: 30px;
}

.logoLogin {
  width: 150px;
}

.recuadroLogin {
  width: 400px;
}

.loginSubmit {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  margin-bottom: 60px;
}

.loginFooter {
  color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 20px;
  text-align: center;
  font-size: 0.5em;
}

.botoneraDebajo {
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}
